import React, { useEffect, useMemo, useRef, useState } from 'react';
import './registration-page.scss';

import { getConfig } from '@edx/frontend-platform';
import { LocaleToggle } from '@npoed/frontend-component-footer';
import axios from 'axios';
import * as EmailValidator from 'email-validator';
import { getMsg, KcTemplateProps, MessageKey } from 'keycloakify';

import { SubmitButton } from '../../components/buttons/submit-button';
import { LabeledFormInput } from '../../components/inputs/labeled-form-input';
import { LabeledSelect } from '../../components/inputs/labeled-select';
import { AuthPageTemplate } from '../../components/layout/auth-page-template';
import { PageLayout } from '../../components/layout/page-layout';
import { SocialForm } from '../../components/misc/social-form';
import { KcContext } from '../../keycloak-app';

const getFieldMessage = (
  msgStr: (key: string) => string,
  kcMessage?: string
) => {
  if (
    kcMessage?.includes('Username already exists.') ||
    kcMessage?.includes('Имя пользователя уже занято.')
  ) {
    return { username: msgStr('usernameAlreadyExistsErrorMsg' as MessageKey) };
  }

  if (
    kcMessage?.includes('Email already exists.') ||
    kcMessage?.includes('E-mail уже существует.')
  ) {
    return { email: msgStr('emailAlreadyExistsErrorMsg' as MessageKey) };
  }
};

type KcContext_Register = Extract<KcContext, { pageId: 'register.ftl' }>;

export const RegistrationPage = ({
  kcContext,
  ...props
}: {
  kcContext: KcContext_Register;
} & KcTemplateProps) => {
  console.log({ kcContext });

  const { social, realm, url, auth } = kcContext;

  const initialFormState = {
    firstName: '',
    lastName: '',
    'user.attributes.patronymic': '',
    username: '',
    email: '',
    password: '',
    'password-confirm': '',
  };

  const [formValues, setFormValues] = useState(initialFormState);
  const [formErrorMessages, setFormErrorMessages] = useState(initialFormState);

  const [hasPatronymic, setHasPatronymic] = useState(true);

  const isFieldValid = (name: string, value: string) => {
    switch (name) {
      case 'username':
        return value.length >= 3 && /^[a-zA-Z0-9.\-_]+$/.test(value);

      case 'password':
        return value.length >= 6;

      case 'password-confirm':
        return value === formValues.password;

      case 'email':
        return EmailValidator.validate(value);

      case 'firstName':
      case 'lastName':
      case 'user.attributes.patronymic':
        return hasPatronymic ? /^[a-zA-Zа-яА-Я\- ]+$/.test(value) : true;
    }
    throw new Error(`Field '${name}' doesn't exist`);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    isFieldValid(name, value)
      ? setFormErrorMessages({ ...formErrorMessages, [name]: '' })
      : setFormErrorMessages({
          ...formErrorMessages,
          [name]: msgStr(`${name}ErrorMsg` as MessageKey),
        });

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    for (const name in formValues) {
      if (!isFieldValid(name, formValues[name])) {
        e.preventDefault();
        break;
      }
    }
  };

  const [isStudentCheckboxEnabled, setIsStudentCheckboxEnabled] =
    useState(false);

  useEffect(() => {
    const fieldMessage = getFieldMessage(msgStr, kcContext?.message?.summary);

    if (fieldMessage !== undefined) {
      setFormErrorMessages({
        ...formErrorMessages,
        ...fieldMessage,
      });
    }
  }, [kcContext]);

  const { msgStr } = getMsg(kcContext);

  const [universitySuggestions, setUniversitySuggestions] = useState<
    { title: string; id: string }[]
  >([]);

  const universityOptions = useMemo(
    () => universitySuggestions.slice(0, 10),
    [universitySuggestions]
  );

  const universityInputTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const onUniversityInputChange = e => {
    if (universityInputTimerRef) {
      clearTimeout(universityInputTimerRef.current);
    }
    universityInputTimerRef.current = setTimeout(
      () =>
        axios
          .get(
            `/realms/${
              getConfig().REALM_NAME
            }/npoed-universities/get-by-substring`,
            {
              params: {
                substring: e.target.value,
              },
            }
          )
          .then(res => setUniversitySuggestions(res.data)),
      500
    );
  };

  return (
    <PageLayout
      loginActionUrl={url.loginUrl}
      registerActionUrl={url.registrationAction}
    >
      <AuthPageTemplate className="registration-page-content">
        <div className="auth-wrapper">
          <div className="auth-section">
            <div className="auth-section__tabs">
              <div className="auth-section__tab auth-section__tab_inactive">
                <a href={url.loginUrl}>{msgStr('loginTitle' as MessageKey)}</a>
              </div>
              <div className="auth-section__tab auth-section__tab_active">
                {msgStr('registerTitle' as MessageKey)}
              </div>
            </div>
          </div>
          <div className="auth-section">
            <div className="rel_title">
              <span className="rel_title__label">
                {msgStr('registerViaTitle' as MessageKey)}
              </span>
            </div>
            <SocialForm providers={social.providers} />
          </div>
          <div className="auth-section">
            <div className="rel_title">
              <span className="rel_title__label">
                {msgStr('orCreateAccount' as MessageKey)}
              </span>
            </div>

            <form
              method="post"
              action={url.registrationAction}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <LabeledFormInput
                label={msgStr('lastName' as MessageKey)}
                className="auth-section__labeled-input secondary-input-variant"
                type="text"
                name="lastName"
                autoComplete="off"
                onChange={handleInputChange}
                errorMessage={formErrorMessages.lastName}
                value={formValues.lastName}
                required
              />

              <LabeledFormInput
                label={msgStr('firstName' as MessageKey)}
                className="auth-section__labeled-input secondary-input-variant"
                type="text"
                name="firstName"
                autoComplete="off"
                onChange={handleInputChange}
                errorMessage={formErrorMessages.firstName}
                value={formValues.firstName}
                required
              />

              {/* This kind of patronymic name is needed to sign the field with keylock */}
              <LabeledFormInput
                label={msgStr('secondName' as MessageKey)}
                className="auth-section__labeled-input secondary-input-variant"
                type="text"
                name="user.attributes.patronymic"
                autoComplete="off"
                onChange={handleInputChange}
                errorMessage={formErrorMessages['user.attributes.patronymic']}
                value={
                  hasPatronymic ? formValues['user.attributes.patronymic'] : ''
                }
                disabled={!hasPatronymic}
                required={hasPatronymic}
              />

              <label className="auth-section__labeled-checkbox">
                <input
                  type="checkbox"
                  checked={!hasPatronymic}
                  onChange={() => setHasPatronymic(prevState => !prevState)}
                />
                {msgStr('noPatronymic' as MessageKey)}
              </label>

              <LabeledFormInput
                label={msgStr('login' as MessageKey)}
                className="auth-section__labeled-input secondary-input-variant"
                type="text"
                name="username"
                autoComplete="off"
                maxLength={30}
                onChange={handleInputChange}
                errorMessage={formErrorMessages.username}
                value={formValues.username}
                required
              />

              <LabeledFormInput
                label={msgStr('email' as MessageKey)}
                className="auth-section__labeled-input secondary-input-variant"
                type="text"
                name="email"
                autoComplete="off"
                onChange={handleInputChange}
                errorMessage={formErrorMessages.email}
                value={formValues.email}
                required
              />

              <LabeledFormInput
                label={msgStr('password' as MessageKey)}
                className="auth-section__labeled-input secondary-input-variant"
                type="password"
                name="password"
                autoComplete="off"
                onChange={handleInputChange}
                errorMessage={formErrorMessages.password}
                value={formValues.password}
                required
              />

              <LabeledFormInput
                label={msgStr('confirmPassword' as MessageKey)}
                className="auth-section__labeled-input secondary-input-variant"
                type="password"
                name="password-confirm"
                autoComplete="off"
                onChange={handleInputChange}
                errorMessage={formErrorMessages['password-confirm']}
                value={formValues['password-confirm']}
                required
              />

              <label className="auth-section__labeled-checkbox">
                <input
                  type="checkbox"
                  onChange={() =>
                    setIsStudentCheckboxEnabled(!isStudentCheckboxEnabled)
                  }
                />
                {msgStr('iAmRussianStudent' as MessageKey)}
              </label>
              {isStudentCheckboxEnabled && (
                <>
                  <LabeledSelect
                    options={universityOptions}
                    optionField="title"
                    onInputChange={onUniversityInputChange}
                    label={msgStr('university' as MessageKey)}
                    className="auth-section__labeled-select select-university"
                    placeholder=""
                    searchOption={true}
                    namesToFields={{
                      'user.attributes.university': 'title',
                      'user.attributes.university_id': 'oldId',
                    }}
                  />
                  <label className="auth-section__labeled-select select-course">
                    {msgStr('yearOfStudy' as MessageKey)}
                    <select name="user.attributes.course">
                      <option value="" selected />
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </select>
                  </label>
                </>
              )}

              <div className="modal-footer">
                <p className="modal-footer__paragraph">
                  {msgStr('provideDataForCertificateParagraph' as MessageKey)}
                </p>
                <p className="modal-footer__paragraph">
                  {msgStr('userAgreementParagraph' as MessageKey)}
                  <a
                    className="modal-footer__link"
                    href="https://openedu.ru/terms"
                  >
                    {' '}
                    {msgStr('userAgreement' as MessageKey)}
                  </a>
                </p>
                <SubmitButton type="submit">
                  {msgStr('registerSubmitButton' as MessageKey)}
                </SubmitButton>
                <p className="modal-footer__paragraph">
                  {msgStr('haveAnAccount' as MessageKey)}
                  <a className="modal-footer__link" href={url.loginUrl}>
                    {' '}
                    {msgStr('imperativeLogin' as MessageKey)}
                  </a>
                </p>
              </div>
            </form>
          </div>
          <div className="register-lang-block">
            <LocaleToggle isGlobeVisible={false} />
          </div>
        </div>
      </AuthPageTemplate>
    </PageLayout>
  );
};
