import React from 'react';
import './login-expired-page.scss';

import { KcTemplateProps, getMsg, MessageKey } from 'keycloakify';

import { PageLayout } from '../../components/layout/page-layout';
import { KcContext } from '../../keycloak-app';

type KcContext_LoginExpiredPage = Extract<
  KcContext,
  { pageId: 'login-page-expired.ftl' }
>;

export const LoginExpiredPage = ({
  kcContext,
  ...props
}: {
  kcContext: KcContext_LoginExpiredPage;
} & KcTemplateProps) => {
  const { realm, url, auth } = kcContext;
  const { msgStr } = getMsg(kcContext);

  return (
    <PageLayout
      className="login-expired-layout"
      loginActionUrl={url.loginUrl}
      registerActionUrl={url.loginUrl}
    >
      <span className="login-expired-title">
        {msgStr('loginExpiredTitle' as MessageKey)}
      </span>
      <span className="login-expired-assumption">
        {msgStr('loginExpiredRestartAssumption' as MessageKey)}{' '}
        <a href={url.loginRestartFlowUrl}>
          {msgStr('clickHere' as MessageKey)}
        </a>
      </span>
    </PageLayout>
  );
};
