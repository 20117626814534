import React, { FC } from 'react';

import { defaultKcProps } from 'keycloakify';
import { KcApp as KcAppBase } from 'keycloakify/lib/components/KcApp';

import { kcContext } from './kcContext';
import { ErrorPage } from './pages/error';
import { LoginExpiredPage } from './pages/login-expired-page';
import { LoginPage } from './pages/login-page';
import { RegistrationPage } from './pages/registration-page';
import { ResetPasswordPage } from './pages/reset-password-page';
import { UpdatePasswordPage } from './pages/update-password-page';
import { UserProfileRegistrationPage } from './pages/user-profile-registration-page';

export type KcContext = NonNullable<typeof kcContext>;

const KeycloakApp: FC = () => {
  const kcProps = {
    ...defaultKcProps,
  };

  switch (kcContext?.pageId) {
    case 'login.ftl':
      return <LoginPage kcContext={kcContext} {...kcProps} />;
    case 'register.ftl':
      return <RegistrationPage kcContext={kcContext} {...kcProps} />;
    case 'login-reset-password.ftl':
      return <ResetPasswordPage kcContext={kcContext} {...kcProps} />;
    case 'login-update-password.ftl':
      return <UpdatePasswordPage kcContext={kcContext} {...kcProps} />;
    case 'register-user-profile.ftl':
      return <UserProfileRegistrationPage kcContext={kcContext} {...kcProps} />;
    case 'error.ftl':
      return <ErrorPage kcContext={kcContext} {...kcProps} />;
    case 'login-page-expired.ftl':
      return <LoginExpiredPage kcContext={kcContext} {...kcProps} />;
    default:
      return (
        <KcAppBase
          kcContext={kcContext!}
          {...{
            ...defaultKcProps,
          }}
        />
      );
  }
};

export default KeycloakApp;
