import React, { useEffect, useState } from 'react';
import './user-profile-registration-page.scss';

import * as EmailValidator from 'email-validator';
import { KcTemplateProps, MessageKey, getMsg } from 'keycloakify';

import { SubmitButton } from '../../components/buttons/submit-button';
import { LabeledFormInput } from '../../components/inputs/labeled-form-input';
import { AuthPageTemplate } from '../../components/layout/auth-page-template';
import { PageLayout } from '../../components/layout/page-layout';
import { KcContext } from '../../keycloak-app';

type KcContext_UserProfileRegistration = Extract<
  KcContext,
  { pageId: 'register-user-profile.ftl' }
>;

export const UserProfileRegistrationPage = ({
  kcContext,
  ...props
}: {
  kcContext: KcContext_UserProfileRegistration;
} & KcTemplateProps) => {
  const { realm, url, auth } = kcContext;

  const initialFormState = {
    username: '',
    password: '',
    'password-confirm': '',
    email: '',
    firstName: '',
    lastName: '',
  };

  const [formValues, setFormValues] = useState(initialFormState);
  const [formErrorMessages, setFormErrorMessages] = useState(initialFormState);

  const isFieldValid = (name: string, value: string) => {
    switch (name) {
      case 'username':
        return value.length >= 3 && /^[a-zA-Z0-9.\-_]+$/.test(value);

      case 'password':
        return value.length >= 6;

      case 'password-confirm':
        return value === formValues.password;

      case 'email':
        return EmailValidator.validate(value);

      case 'firstName':
      case 'lastName':
        return /^[a-zA-Zа-яА-Я\- ]+$/.test(value);
    }
    throw new Error(`Field '${name}' doesn't exist`);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    isFieldValid(name, value)
      ? setFormErrorMessages({ ...formErrorMessages, [name]: '' })
      : setFormErrorMessages({
          ...formErrorMessages,
          [name]: msgStr(`${name}ErrorMsg` as MessageKey),
        });

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    for (const name in formValues) {
      if (!isFieldValid(name, formValues[name])) {
        e.preventDefault();
        break;
      }
    }
  };

  useEffect(() => {
    if (
      kcContext?.message?.summary.includes('Username already exists') ||
      kcContext?.message?.summary.includes('Имя пользователя уже занято')
    ) {
      setFormErrorMessages({
        ...formErrorMessages,
        username: msgStr('usernameAlreadyExistsErrorMsg' as MessageKey),
      });
    }
  }, [kcContext]);

  const { msgStr } = getMsg(kcContext);

  return (
    <PageLayout loginActionUrl={url.loginUrl} registerActionUrl={url.loginUrl}>
      <AuthPageTemplate className="user-profile-registration-page-content">
        <div className="auth-wrapper">
          <div className="auth-section">
            <div className="rel_title">
              <span className="rel_title__label">
                {msgStr('registerTitle' as MessageKey)}
              </span>
            </div>

            <form
              method="post"
              action={url.loginAction}
              onSubmit={handleSubmit}
            >
              <LabeledFormInput
                className="auth-section__labeled-input"
                label={msgStr('username' as MessageKey)}
                name="username"
                autoComplete="off"
                errorMessage={formErrorMessages.username}
                value={formValues.username}
                onChange={handleInputChange}
                maxLength={255}
                required
              />

              <LabeledFormInput
                className="auth-section__labeled-input"
                label={msgStr('password' as MessageKey)}
                name="password"
                autoComplete="off"
                type="password"
                errorMessage={formErrorMessages.password}
                value={formValues.password}
                onChange={handleInputChange}
                maxLength={255}
                required
              />

              <LabeledFormInput
                className="auth-section__labeled-input"
                label={msgStr('confirmNewPassword' as MessageKey)}
                name="password-confirm"
                autoComplete="off"
                type="password"
                errorMessage={formErrorMessages['password-confirm']}
                value={formValues['password-confirm']}
                onChange={handleInputChange}
                maxLength={255}
                required
              />

              <LabeledFormInput
                className="auth-section__labeled-input"
                label={msgStr('email' as MessageKey)}
                name="email"
                autoComplete="off"
                errorMessage={formErrorMessages.email}
                value={formValues.email}
                onChange={handleInputChange}
                maxLength={255}
                required
              />

              <LabeledFormInput
                className="auth-section__labeled-input"
                label={msgStr('firstName' as MessageKey)}
                name="firstName"
                autoComplete="off"
                errorMessage={formErrorMessages.firstName}
                value={formValues.firstName}
                onChange={handleInputChange}
                maxLength={255}
                required
              />

              <LabeledFormInput
                className="auth-section__labeled-input"
                label={msgStr('lastName' as MessageKey)}
                name="lastName"
                autoComplete="off"
                errorMessage={formErrorMessages.lastName}
                value={formValues.lastName}
                onChange={handleInputChange}
                maxLength={255}
                required
              />

              <SubmitButton type="submit">
                {msgStr('registerSubmitButton' as MessageKey)}
              </SubmitButton>
            </form>
          </div>
        </div>
      </AuthPageTemplate>
    </PageLayout>
  );
};
