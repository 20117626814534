import React from 'react';

import './form-input.scss';

export type FormInputProps = {
  className?: string;
} & React.HTMLProps<HTMLInputElement>;

export const FormInput = ({ className = '', ...rest }: FormInputProps) => {
  return <input className={'form-input ' + className} {...rest} />;
};
