import { kcMessages } from 'keycloakify';

Object.assign(kcMessages['en'], {
  headerCourses: 'Courses',
  headerPrograms: 'Programs',
  headerCollaboration: 'Collaboration',
  headerAbout: 'About',
  headerSearch: 'Search',
  headerLoginButton: 'Login',
  headerRegisterButton: 'Register',

  footerCoursesCatalog: 'Courses catalog',
  footerProgramsCatalog: 'Programs catalog',
  footerEducationDirections: 'Education directions',
  footerCopyright: '@ 2022 Open Education',
  footerAbout: 'About',
  footerFAQ: 'FAQ',
  footerAskQuestion: 'Ask a question',
  footerSystemRequirements: 'System requirements',
  footerUserAgreement: 'User agreement',
  footerContactInformation: 'Contact information',
  footerPress: 'Press',
  footerPersonalDataPolicy: 'Personal data policy',

  loginSubmitButton: 'Login',
  registerSubmitButton: 'Register',
  resetPasswordSubmitButton: 'Reset password',
  updatePasswordSubmitButton: 'Change password',
  loginTitle: 'Login',
  registerTitle: 'Registration',
  updatePasswordTitle: 'Password change',
  resetPasswordTitle: 'Password reset',
  loginViaTitle: 'or login via',
  registerViaTitle: 'Registration via',
  forgetPassword: 'Forget password?',
  firstTimeTitle: 'First time here?',
  createAccount: 'Create account',
  orCreateAccount: 'or create new account',
  username: 'Username',
  lastName: 'Last Name',
  firstName: 'First Name',
  secondName: 'Second Name',
  login: 'Login',
  email: 'E-mail',
  usernameOrEmail: 'Username or email:',
  password: 'Password:',
  confirmPassword: 'Confirm password',
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmNewPassword: 'Again',
  iAmRussianStudent: "I'm a russian university student",
  noPatronymic: "I don't have second name",
  university: 'University',
  yearOfStudy: 'Year of study',
  provideDataForCertificateParagraph:
    'Please, provide your real name and surname, because its will be used in course certificate',
  userAgreement: 'User Agreement',
  userAgreementParagraph: 'By pressing Register button I accept terms of',
  haveAnAccount: 'Have got an account?',
  imperativeLogin: 'Login',
  resetPasswordParagraph:
    'Please, input your e-mail. We send to it password resetting instruction',

  usernameErrorMsg:
    'The username can only contain numbers, Latin characters and .-_\nMinimal length is 3',
  usernameAlreadyExistsErrorMsg:
    'The user with such login already exists or the associated account has been deleted',
  passwordErrorMsg: 'Password too short, minimum length 6',
  'password-confirmErrorMsg': 'Passwords do not match',
  emailErrorMsg: 'Invalid email',
  emailAlreadyExistsErrorMsg: 'User with this e-mail already exists',
  firstNameErrorMsg:
    'The field can only contain characters in Cyrillic, Latin, space and hyphen',
  lastNameErrorMsg:
    'The field can only contain characters in Cyrillic, Latin, space and hyphen',
  'user.attributes.patronymicErrorMsg':
    'The field can only contain characters in Cyrillic, Latin, space and hyphen',

  loginExpiredTitle: 'Page has expired',
  loginExpiredRestartAssumption: 'To restart the login process',
  clickHere: 'Click here',
});

Object.assign(kcMessages['ru'], {
  headerCourses: 'Курсы',
  headerPrograms: 'Программы',
  headerCollaboration: 'Сотрудничество',
  headerAbout: 'О проекте',
  headerSearch: 'Поиск',
  headerLoginButton: 'Вход',
  headerRegisterButton: 'Регистрация',

  footerCoursesCatalog: 'Каталог курсов',
  footerProgramsCatalog: 'Каталог программ',
  footerEducationDirections: 'Направления подготовки',
  footerCopyright: '@ 2022 Открытое образование',
  footerAbout: 'О проекте',
  footerFAQ: 'Вопрос-ответ',
  footerAskQuestion: 'Задать вопрос',
  footerSystemRequirements: 'Системные требования',
  footerUserAgreement: 'Пользовательское соглашение',
  footerContactInformation: 'Контактная информация',
  footerPress: 'Контакты для СМИ',
  footerPersonalDataPolicy: 'Политика в отношении перс. данных',

  loginSubmitButton: 'Войти',
  registerSubmitButton: 'Зарегистрироваться',
  resetPasswordSubmitButton: 'Сбросить пароль',
  updatePasswordSubmitButton: 'Сменить пароль',
  loginTitle: 'Войти',
  registerTitle: 'Регистрация',
  updatePasswordTitle: 'Смена пароля',
  resetPasswordTitle: 'Сброс пароля',
  loginViaTitle: 'или войдите с помощью',
  registerViaTitle: 'Зарегистрироваться через',
  forgetPassword: 'Забыли пароль?',
  firstTimeTitle: 'Впервые на платформе?',
  createAccount: 'Создать аккаунт',
  orCreateAccount: 'или создать новый аккаунт',
  username: 'Имя пользователя',
  lastName: 'Фамилия',
  firstName: 'Имя',
  secondName: 'Отчество',
  login: 'Логин',
  email: 'E-mail',
  usernameOrEmail: 'Логин или e-mail:',
  password: 'Пароль',
  confirmPassword: 'Повторите',
  currentPassword: 'Текущий пароль',
  newPassword: 'Новый пароль',
  confirmNewPassword: 'И еще раз',
  iAmRussianStudent: 'Я - студент российского вуза',
  noPatronymic: 'Нет отчества',
  university: 'Университет',
  yearOfStudy: 'Год обучения',
  provideDataForCertificateParagraph:
    'Пожалуйста, указывайте настоящие имя и фамилию, они будут вписаны в сертификат о прохождении курса',
  userAgreement: 'пользовательского соглашения',
  userAgreementParagraph:
    'Нажимая кнопку зарегистрироваться, я принимаю условия',
  haveAnAccount: 'Уже есть аккаунт?',
  imperativeLogin: 'Войдите',
  resetPasswordParagraph:
    'Пожалуйста, укажите ваш e-mail. Мы отправим на него инструкцию по сбросу пароля',

  usernameErrorMsg:
    'Имя пользователя может содержать только цифры, символы латиницы и .-_\nМинимальная длина 3',
  usernameAlreadyExistsErrorMsg:
    'Пользователь с таким логином уже существует или связанный с ним аккаунт был удален',
  passwordErrorMsg: 'Пароль слишком короткий, минимальная длина 6',
  'password-confirmErrorMsg': 'Пароли не совпадают',
  emailErrorMsg: 'Некорректный адрес электронной почты',
  emailAlreadyExistsErrorMsg: 'Пользователь с таким e-mail уже существует',
  firstNameErrorMsg:
    'Поле может содержать только символы кириллицы, латиницы, пробел и дефис',
  lastNameErrorMsg:
    'Поле может содержать только символы кириллицы, латиницы, пробел и дефис',
  'user.attributes.patronymicErrorMsg':
    'Поле может содержать только символы кириллицы, латиницы, пробел и дефис',

  loginExpiredTitle: 'Срок действия страницы истек',
  loginExpiredRestartAssumption: 'Чтобы перезайти в систему',
  clickHere: 'Нажмите сюда',
});
