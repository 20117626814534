import React, { FC, useEffect, useState } from 'react';

import { changeLocale } from 'keycloakify';

type AvailableLocales = 'ru' | 'en';

export const ThemeContext = React.createContext({
  locale: 'ru',
  setLocale: (locale: AvailableLocales) => {},
});

export const ThemeProvider: FC = ({ children }) => {
  const [currentLocale, setCurrentLocale] = useState<AvailableLocales>(() => {
    const localData = localStorage.getItem('locale');
    if (localData === 'ru' || localData === 'en') {
      return localData;
    }
    return 'ru';
  });

  useEffect(() => {
    localStorage.setItem('locale', currentLocale);
  }, [currentLocale]);

  const setLocale = (lang: AvailableLocales) => {
    setCurrentLocale(lang);
    changeLocale({
      kcContext: {
        locale: {
          supported: [
            { languageTag: 'ru', url: '/' },
            { languageTag: 'en', url: '/' },
          ],
        },
      },
      kcLanguageTag: currentLocale,
    });
  };

  return (
    <ThemeContext.Provider
      value={{ locale: currentLocale, setLocale: setLocale }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
