import { useState } from 'react';

import './select.scss';
import classNames from 'classnames';

import ArrowDown from '../../../assets/images/select-arrow-down.svg';
import ArrowUp from '../../../assets/images/select-arrow-up.svg';
import { FormInput } from '../form-input';

type Option = { [key: string]: any };

export type SelectProps = {
  optionField: string;
  options: Option[];
  placeholder?: string;
  className?: string;
  searchOption?: boolean;
  onInputChange?: (e) => any;
  namesToFields?: { [name: string]: string }; // sorry for that
};

export const Select = ({
  options,
  optionField,
  placeholder = '',
  className = '',
  searchOption = false,
  onInputChange,
  namesToFields,
}: SelectProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Option>({
    [optionField]: placeholder,
  });

  const cutString = (str: string) => {
    const maxLength = 52;
    if (str.length > maxLength) {
      return str.slice(0, maxLength).trim() + '...';
    }
    return str;
  };

  return (
    <div className={'select-container ' + className}>
      {namesToFields &&
        Object.entries(namesToFields).map(([name, field]) => {
          return (
            selectedItem[field] && (
              <input
                name={name}
                value={selectedItem[field]}
                style={{
                  display: 'none',
                }}
              />
            )
          );
        })}
      <div
        className={classNames('select-container__control', {
          'select-container__control--expanded': isExpanded,
        })}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{cutString(selectedItem[optionField])}</span>
        {isExpanded ? <ArrowUp /> : <ArrowDown />}
      </div>
      {isExpanded && (
        <div
          className={classNames('select-container__input', {
            'select-container__input--expanded': isExpanded,
          })}
        >
          <div className="select-search-input__wrapper">
            {searchOption && (
              <FormInput
                className="select-search-input"
                onChange={onInputChange}
              />
            )}
          </div>

          {options.map(option => (
            <div
              className="select-option"
              onClick={() => {
                setSelectedItem(option);
                setIsExpanded(false);
              }}
            >
              {option[optionField]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
