import React from 'react';
import './error-page.scss';

import { KcTemplateProps } from 'keycloakify';

import { PageLayout } from '../../components/layout/page-layout';
import { KcContext } from '../../keycloak-app';

type KcContext_ErrorPage = Extract<KcContext, { pageId: 'error.ftl' }>;

export const ErrorPage = ({
  kcContext,
  ...props
}: {
  kcContext: KcContext_ErrorPage;
} & KcTemplateProps) => {
  const { realm, url, auth } = kcContext;

  return (
    <PageLayout
      className="error-layout"
      loginActionUrl={url.loginUrl}
      registerActionUrl={url.loginUrl}
    >
      <span className="error-type">{kcContext.message.type}</span>
      <span className="error-summary">{kcContext.message.summary}</span>
    </PageLayout>
  );
};
