import React from 'react';

import './labeled-select.scss';
import { Select } from '../select';
import { SelectProps } from '../select/select';

export type LabeledSelectProps = {
  placeholder?: string;
  label: string;
} & SelectProps;

export const LabeledSelect = ({
  label,
  className = '',
  ...rest
}: LabeledSelectProps) => {
  return (
    <label className={'labeled-select ' + className}>
      {label}
      <Select {...rest} />
    </label>
  );
};
