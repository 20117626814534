import React from 'react';
import './login-page.scss';

import { LocaleToggle } from '@npoed/frontend-component-footer';
import { KcTemplateProps, MessageKey, getMsg } from 'keycloakify';

import { SubmitButton } from '../../components/buttons/submit-button';
import { LabeledFormInput } from '../../components/inputs/labeled-form-input';
import { AuthPageTemplate } from '../../components/layout/auth-page-template';
import { PageLayout } from '../../components/layout/page-layout';
import { SocialForm } from '../../components/misc/social-form';
import { KcContext } from '../../keycloak-app';

type KcContext_Login = Extract<KcContext, { pageId: 'login.ftl' }>;

export const LoginPage = ({
  kcContext,
  ...props
}: {
  kcContext: KcContext_Login;
} & KcTemplateProps) => {
  const {
    social,
    realm,
    url,
    usernameEditDisabled,
    login,
    auth,
    registrationDisabled,
  } = kcContext;

  const { msgStr } = getMsg(kcContext);

  return (
    <PageLayout
      loginActionUrl={url.loginAction}
      registerActionUrl={url.registrationUrl}
    >
      <AuthPageTemplate className="login-page-content">
        <div className="auth-wrapper">
          <div className="auth-section">
            <div className="auth-section__tabs">
              <div className="auth-section__tab auth-section__tab_active">
                {msgStr('loginTitle' as MessageKey)}
              </div>
              <div className="auth-section__tab auth-section__tab_inactive">
                <a href={url.registrationUrl}>
                  {msgStr('registerTitle' as MessageKey)}
                </a>
              </div>
            </div>
          </div>
          <div className="auth-section">
            {kcContext.message !== undefined &&
              (kcContext.message.type !== 'warning' ||
                !kcContext.isAppInitiatedAction) && (
                <div className="auth-section__error-message">
                  {kcContext.message.summary}
                </div>
              )}
            <form method="post" action={url.loginAction} className="auth-form">
              <LabeledFormInput
                label={msgStr('usernameOrEmail' as MessageKey)}
                className="auth-section__labeled-input"
                type="text"
                name="username"
                tabIndex={1}
                required={true}
                autoComplete="off"
              />
              <LabeledFormInput
                label={msgStr('password' as MessageKey)}
                className="auth-section__labeled-input"
                type="password"
                name="password"
                tabIndex={1}
                required={true}
                autoComplete="off"
              />
              <input
                name="rememberMe"
                id="rememberMe"
                type="checkbox"
                checked={true}
                onChange={() => {}}
                style={{ display: 'none' }}
              />
              <a
                className="reset-password-link"
                href={url.loginResetCredentialsUrl}
                tabIndex={2}
              >
                {msgStr('forgetPassword' as MessageKey)}
              </a>
              <SubmitButton
                className="auth-section__submit-button"
                type="submit"
                tabIndex={1}
              >
                {msgStr('loginSubmitButton' as MessageKey)}
              </SubmitButton>
            </form>
          </div>
          <div className="auth-section">
            <div className="rel_title">
              <span className="rel_title__label">
                {msgStr('loginViaTitle' as MessageKey)}
              </span>
            </div>
            <SocialForm providers={social.providers} />
          </div>
          <div className="login-lang-block">
            <LocaleToggle isGlobeVisible={false} />
          </div>
        </div>
        <div className="to_registration-wrapper">
          <span className="to_registration-wrapper__label">
            {msgStr('firstTimeTitle' as MessageKey)}
          </span>
          <a
            className="to_registration-wrapper__link"
            href={url.registrationUrl}
          >
            {msgStr('createAccount' as MessageKey)}
          </a>
        </div>
      </AuthPageTemplate>
    </PageLayout>
  );
};
