import React, { FC } from 'react';

import './submit-button.scss';

export type SubmitButtonProps = {
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const SubmitButton: FC<SubmitButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <button className={'btn-submit ' + className} {...rest}>
      {children}
    </button>
  );
};
