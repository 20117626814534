import React from 'react';

import {
  initialize,
  subscribe,
  APP_READY,
  getConfig,
  mergeConfig,
} from '@edx/frontend-platform';
import { SegmentAnalyticsService } from '@edx/frontend-platform/analytics';
import { AxiosJwtAuthService } from '@edx/frontend-platform/auth';
import { AppProvider, AppContext } from '@edx/frontend-platform/react';
import { messages as footerMessages } from '@npoed/frontend-component-footer';
import { messages as headerMessages } from '@npoed/frontend-component-header';
import { render } from 'react-dom';

import './kcMessagesExtensions';

import '@npoed/frontend-component-footer/dist/_footer.scss';

import KeycloakApp from './keycloak-app';
import { ThemeProvider } from './themeContext';

class AuthService extends AxiosJwtAuthService {
  fetchAuthenticatedUser() {}
}

class AnalyticsService extends SegmentAnalyticsService {
  initializeSegment() {}
}

initialize({
  messages: [headerMessages, footerMessages],
  authService: AuthService,
  analyticsService: AnalyticsService,
  handlers: {
    config: () => {
      mergeConfig(
        {
          CONTACT_URL: process.env.CONTACT_URL || null,
          CREDENTIALS_BASE_URL: process.env.CREDENTIALS_BASE_URL || null,
          ENTERPRISE_LEARNER_PORTAL_HOSTNAME:
            process.env.ENTERPRISE_LEARNER_PORTAL_HOSTNAME || null,
          ENABLE_JUMPNAV: process.env.ENABLE_JUMPNAV || null,
          ENABLE_NOTICES: process.env.ENABLE_NOTICES || null,
          INSIGHTS_BASE_URL: process.env.INSIGHTS_BASE_URL || null,
          SEARCH_CATALOG_URL: process.env.SEARCH_CATALOG_URL || null,
          SOCIAL_UTM_MILESTONE_CAMPAIGN:
            process.env.SOCIAL_UTM_MILESTONE_CAMPAIGN || null,
          STUDIO_BASE_URL: process.env.STUDIO_BASE_URL || null,
          SUPPORT_URL: process.env.SUPPORT_URL || null,
          SUPPORT_URL_CALCULATOR_MATH:
            process.env.SUPPORT_URL_CALCULATOR_MATH || null,
          SUPPORT_URL_ID_VERIFICATION:
            process.env.SUPPORT_URL_ID_VERIFICATION || null,
          SUPPORT_URL_VERIFIED_CERTIFICATE:
            process.env.SUPPORT_URL_VERIFIED_CERTIFICATE || null,
          TERMS_OF_SERVICE_URL: process.env.TERMS_OF_SERVICE_URL || null,
          TWITTER_HASHTAG: process.env.TWITTER_HASHTAG || null,
          TWITTER_URL: process.env.TWITTER_URL || null,
          LEGACY_THEME_NAME: process.env.LEGACY_THEME_NAME || null,
          SSO_SWITCH_LOCALE_URL: process.env.SSO_SWITCH_LOCALE_URL,
          TELEGRAM_URL: process.env.TELEGRAM_URL,
          VK_URL: process.env.VK_URL,
          OK_URL: process.env.OK_URL,
          PLP_BASE_URL: process.env.PLP_BASE_URL,
          COURSES_URL: process.env.COURSES_URL,
          PROGRAMS_URL: process.env.PROGRAMS_URL,
          SPECIALIZE_URL: process.env.SPECIALIZE_URL,
          TRAJECTORY_URL: process.env.TRAJECTORY_URL,
          PARTNERSHIP_URL: process.env.PARTNERSHIP_URL,
          TERMS_URL: process.env.TERMS_URL,
          CONTACTS_URL: process.env.CONTACTS_URL,
          FOR_MEDIA_URL: process.env.FOR_MEDIA_URL,
          PDPOLICY_URL: process.env.PDPOLICY_URL,
          CATALOG_URL: process.env.CATALOG_URL,
          FEEDBACK_URL: process.env.FEEDBACK_URL,
          REQUIREMENTS_URL: process.env.REQUIREMENTS_URL,
          MY_COURSES_URL: process.env.MY_COURSES_URL,
          MY_PROGRAMS_URL: process.env.MY_PROGRAMS_URL,
          FAQ_URL: process.env.FAQ_URL,
          ACCOUNT_URL: process.env.ACCOUNT_URL,
          ABOUT_URL: process.env.ABOUT_URL,
          NEWS_SUBSCRIPTION_URL: process.env.NEWS_SUBSCRIPTION_URL,
          REGISTER_URL: process.env.REGISTER_URL,
          REALM_NAME: process.env.REALM_NAME,
        },
        'LearnerAppConfig'
      );
      mergeConfig({ ...(window as any).env }, 'WindowConfig');
    },
  },
});

subscribe(APP_READY, () => {
  render(
    <AppProvider>
      <AppContext.Provider
        value={{
          authenticatedUser: null,
          config: getConfig(),
        }}
      >
        <ThemeProvider>
          <KeycloakApp />
        </ThemeProvider>
      </AppContext.Provider>
    </AppProvider>,
    document.getElementById('root')
  );
});
