import React, { FC } from 'react';

import './page-layout.scss';

import Footer from '@npoed/frontend-component-footer';
import { LearningHeader as Header } from '@npoed/frontend-component-header';
import classNames from 'classnames';

export type PageLayoutProps = {
  className?: string;
  loginActionUrl: string;
  registerActionUrl: string;
};

export const PageLayout: FC<PageLayoutProps> = ({
  children,
  loginActionUrl,
  registerActionUrl,
  className = '',
}) => {
  return (
    <div className={classNames('page-layout', className)}>
      <Header
        loginActionUrl={loginActionUrl}
        registerActionUrl={registerActionUrl}
        isBviVisible={true}
        isFixed={true}
      />
      <main className="page-layout__content">{children}</main>
      <Footer />
    </div>
  );
};
