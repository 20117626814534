import React, { FC } from 'react';
import './social-form.scss';

import classNames from 'classnames';

import providerAliasToIcon from './provider-icons';

type Provider = {
  loginUrl: string;
  alias: string;
  providerId: string;
  displayName: string;
};

type AuthViaButtonsProps = {
  providers?: Provider[];
  className?: string;
};

const SocialForm: FC<AuthViaButtonsProps> = ({ providers, className }) => {
  const renderIcon = (alias: string) => {
    const icon = providerAliasToIcon[alias];
    if (!icon) return <div className="social-form__img" />;
    const { Component, backgroundColor } = icon;
    return (
      <div className="social-form__img" style={{ backgroundColor }}>
        <Component />
      </div>
    );
  };

  return (
    <>
      {providers && (
        <ul className={classNames('social-form', className)}>
          {providers.map(({ loginUrl, alias, providerId, displayName }) => (
            <li key={providerId} className={`social-form__item ${alias}`}>
              <a className="social-form__link" href={loginUrl}>
                {renderIcon(alias)}
                <span className="social-form__label">{displayName}</span>
              </a>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default SocialForm;
