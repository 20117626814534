import React, { useState } from 'react';
import './reset-password-page.scss';

import { KcTemplateProps, MessageKey, getMsg } from 'keycloakify';
import validator from 'validator';

import { SubmitButton } from '../../components/buttons/submit-button';
import { LabeledFormInput } from '../../components/inputs/labeled-form-input';
import { AuthPageTemplate } from '../../components/layout/auth-page-template';
import { PageLayout } from '../../components/layout/page-layout';
import { KcContext } from '../../keycloak-app';

type KcContext_ResetPassword = Extract<
  KcContext,
  { pageId: 'login-reset-password.ftl' }
>;

export const ResetPasswordPage = ({
  kcContext,
  ...props
}: {
  kcContext: KcContext_ResetPassword;
} & KcTemplateProps) => {
  const { realm, url, auth } = kcContext;

  const [email, setEmail] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');

  const handleSubmit = (e: any) => {
    setEmailErrorMsg('');

    if (!validator.isEmail(email)) {
      setEmailErrorMsg('Введите правильный адрес электронной почты.');
      e.preventDefault();
    }
  };

  const { msgStr } = getMsg(kcContext);

  return (
    // Найти способ передать в registerActionUrl ссылку на регистрацию
    <PageLayout loginActionUrl={url.loginUrl} registerActionUrl={url.loginUrl}>
      <AuthPageTemplate className="reset-password-page-content">
        <div className="auth-wrapper">
          <div className="auth-section">
            <div className="rel_title">
              <span className="rel_title__label">
                {msgStr('resetPasswordTitle' as MessageKey)}
              </span>
            </div>

            <form
              method="post"
              action={url.loginAction}
              onSubmit={handleSubmit}
            >
              <p>{msgStr('resetPasswordParagraph' as MessageKey)}</p>
              <LabeledFormInput
                className="auth-section__labeled-input"
                label={msgStr('email' as MessageKey)}
                name="username"
                autoComplete="off"
                onChange={e => setEmail(e.target.value)}
                errorMessage={emailErrorMsg}
              />

              <SubmitButton type="submit">
                {msgStr('resetPasswordSubmitButton' as MessageKey)}
              </SubmitButton>
            </form>
          </div>
        </div>
      </AuthPageTemplate>
    </PageLayout>
  );
};
