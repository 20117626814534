import React from 'react';

import './labeled-form-input.scss';
import { FormInput } from '../form-input';

export type LabeledFormInputProps = {
  className?: string;
  label: string;
  errorMessage?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const LabeledFormInput = ({
  className = '',
  label,
  errorMessage,
  ...rest
}: LabeledFormInputProps) => {
  return (
    <label className={'labeled-form-input ' + className}>
      {label}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <FormInput {...rest} />
    </label>
  );
};
