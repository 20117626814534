import React, { FC } from 'react';

import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import itmoIconSrc from '../../../assets/images/providers/itmo.png';
import MailruIcon from '../../../assets/images/providers/mailru.svg';
import misisIconSrc from '../../../assets/images/providers/misis.png';
import msuIconSrc from '../../../assets/images/providers/msu.png';
import PolytechIcon from '../../../assets/images/providers/polytech.svg';
import scosIconSrc from '../../../assets/images/providers/scos.png';
import uni2035IconSrc from '../../../assets/images/providers/uni2035.png';
import urfuIconSrc from '../../../assets/images/providers/urfu.png';

const providerAliasToIcon: {
  [alias: string]: { Component: FC; backgroundColor?: string } | undefined;
} = {
  vk: {
    Component: () => <FontAwesomeIcon icon={brands('vk')} />,
    backgroundColor: '#4c75a3',
  },
  twitter: {
    Component: () => <FontAwesomeIcon icon={brands('twitter')} />,
    backgroundColor: '#00abe1',
  },
  google: {
    Component: () => <FontAwesomeIcon icon={brands('google')} />,
    backgroundColor: '#cc3732',
  },
  mailru: {
    Component: () => <MailruIcon style={{ width: '20px', height: '20px' }} />,
    backgroundColor: '#005bef',
  },
  spbstu: {
    Component: () => <PolytechIcon />,
    backgroundColor: '#37B34A',
  },
  misis: {
    Component: () => (
      <img
        alt="misis"
        src={misisIconSrc}
        style={{ width: '22px', height: '22px' }}
      />
    ),
    backgroundColor: '#fff',
  },
  msu: {
    Component: () => (
      <img
        alt="msu"
        src={msuIconSrc}
        style={{ width: '25px', height: '25px' }}
      />
    ),
    backgroundColor: '#fff',
  },
  urfu: {
    Component: () => (
      <img
        alt="urfu"
        src={urfuIconSrc}
        style={{ width: '100%', height: '100%' }}
      />
    ),
    backgroundColor: '#fff',
  },
  scos: {
    Component: () => (
      <img
        alt="scos"
        src={scosIconSrc}
        style={{ width: '100%', height: '100%' }}
      />
    ),
    backgroundColor: '#fff',
  },
  itmo: {
    Component: () => (
      <img
        alt="itmo"
        src={itmoIconSrc}
        style={{ width: '100%', height: '100%' }}
      />
    ),
    backgroundColor: '#fff',
  },
  uni2035: {
    Component: () => (
      <img
        alt="uni2035"
        src={uni2035IconSrc}
        style={{ width: '100%', height: '100%' }}
      />
    ),
    backgroundColor: '#fff',
  },
};

export default providerAliasToIcon;
