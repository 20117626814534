import React, { FC } from 'react';

import './auth-page-template.scss';

export type AuthPageTemplateProps = {
  className?: string;
};

export const AuthPageTemplate: FC<AuthPageTemplateProps> = ({
  children,
  className = '',
}) => {
  return <div className={'content-wrapper ' + className}>{children}</div>;
};
